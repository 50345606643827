import React from "react"

import PrivacyHero from "../components/Privacy/PrivacyHero"
import PrivacyBody from "../components/Privacy/PrivacyBody"

import MainLayout from "../layouts/MainLayout"
import { Helmet } from "react-helmet"

const Privacy = () => {
  return (
    <MainLayout>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="C.Liu" />

        <title>Privacy Policy | Café Kraken</title>
        <meta name="description" content="Feel free to get in touch with us!" />
      </Helmet>
      <section className="privacy">
        <PrivacyHero></PrivacyHero>
        <PrivacyBody></PrivacyBody>
      </section>
    </MainLayout>
  )
}

export default Privacy
