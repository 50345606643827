import React from "react"
import { motion } from "framer-motion"
import { pageFade, pageTransistion } from "../../helpers/FramerMotionAnim"
import { Container } from "react-bootstrap"
import { Parallax } from "react-scroll-parallax"

const PrivacyHero = () => {
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageFade}
      transition={pageTransistion}
    >
      <section className="privacy--hero">
        <Container className="text-center">
          <h1 className="">Privacy Policy</h1>

          <h6 className="p-5">
            By using our website you agree to our cookie & privacy policy.
          </h6>
        </Container>
      </section>
    </motion.div>
  )
}

export default PrivacyHero
